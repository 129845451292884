import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const EditUserContext = createContext({
  editUser: null,
  setEditUser: () => {
  },
});

export const EditUserProvider = ({
  defaultEditUser = null,
  children,
}) => {
  const [u, setU] = useState(defaultEditUser);
  const setEditUser = useCallback((newUser) => setU(newUser), []);
  return (
    <EditUserContext.Provider
      value={{
        editUser: u,
        setEditUser,
      }}
    >
      {children}
    </EditUserContext.Provider>
  );
};

export const useEditUser = () => useContext(EditUserContext);
